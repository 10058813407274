import React, { useCallback, useEffect, useState } from 'react'
import { Box } from 'rebass'
import { ThemeProvider } from 'emotion-theming'
import { useTranslation } from 'react-i18next'

import theme, { THEMES } from '../theme'
import Home from './Home'

import SessionTimedOut from './SessionTimedOut'
import DigitalHuman from './DigitalHuman'
import { getToken } from '../socket'
import FatalError from '../customPlugins/MayaErrors/FatalError'
import { LanguageOption } from '../customPlugins/LanguageSelector/LanguageOption'
import { useChangeLanguage } from './hooks/useChangeLanguage'
import { LanguageInformation } from '../customPlugins/LanguageSelector/LanguageInformation'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#004C9C',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0
}

const getTheme = (queryTheme: string | null) => {
  if (!queryTheme) {
    return window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? THEMES.DARK
      : THEMES.DEFAULT
  }

  if (queryTheme.toLowerCase() === 'dark') {
    return THEMES.DARK
  }
  return THEMES.DEFAULT
}

// converts 'en-us' to 'en-US' so i18next doesn't complain
export const transformLanguageToISO = (lang: string) => {
  if (lang && !lang.includes('-')) return lang
  const langSplit = (lang.split('-') as unknown) as string[]
  const location = langSplit[1].toUpperCase()
  return `${langSplit[0]}-${location}`
}

const App = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const embeddedApp = Boolean(queryParams.get('embedded'))
  const timeoutTestTime = Number(queryParams.get('test_timeout_time'))
  // @ts-ignore
  var viewport = window.visualViewport
  const { i18n } = useTranslation()

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState('welcome')
  const [activeTheme, setActiveTheme] = useState(THEMES.DARK)
  const [speak, setSpeak] = useState(true)
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [disableDH, setDisableDH] = useState(true)
  const [initError, setInitError] = useState('')
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [supportedLanguages, setSupportedLanguages] = useState<
    LanguageOption[]
  >([])
  const [personaId, setPersonaId] = useState(
    process.env.REACT_APP_UNEEQ_PERSONA_ID || ''
  )

  const [height, setHeight] = useState(viewport?.height)

  const setNewLanguage = useCallback(
    (lang: LanguageInformation) => {
      // add language bundle and overrides current one if already added
      i18n.addResourceBundle(
        transformLanguageToISO(lang.key),
        'translation',
        lang.translation,
        true,
        true
      )
      setCurrentLanguage(transformLanguageToISO(lang.key))
      setPersonaId(lang.personaId)
    },
    [i18n]
  )
  const restart = () => goTo('welcome')

  useChangeLanguage(setNewLanguage)

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }
  }, [currentLanguage, i18n])

  useEffect(() => {
    viewport?.addEventListener('resize', () => {
      step === 'digitalHuman' && setHeight(viewport.height)
    })
  }, [step, viewport])

  useEffect(() => {
    const scrollToTop = () => {
      if (step === 'digitalHuman') {
        document.documentElement.scrollTop = 0
      }
    }
    scrollToTop()

    // @ts-ignore
    window.visualViewport?.addEventListener('scrollEnd', scrollToTop)
    // @ts-ignore
    return window.visualViewport?.removeEventListener('scrollEnd', scrollToTop)
  })

  useEffect(() => {
    getToken()
      .then(
        ({
          message,
          disableDigitalHuman,
          logo,
          title,
          i18n: i18nProps,
          supportedLanguages
        }: any) => {
          const headingMessage = message || ' '
          setHeadingMessage(headingMessage)
          setDisableDH(disableDigitalHuman)
          setLogo(logo)
          if (title && !embeddedApp) {
            document.title = title
          }

          if (i18nProps) {
            setNewLanguage(i18nProps)
          }

          setSupportedLanguages(
            supportedLanguages.map((lang: any) => ({
              ...lang,
              code: transformLanguageToISO(lang.code)
            }))
          )
        }
      )
      .catch(({ message }: any) => {
        setInitError(message)
      })
  }, [embeddedApp, setNewLanguage])

  return (
    <ThemeProvider theme={theme(activeTheme, embeddedApp)}>
      <Box
        sx={{
          ...backgroundStyle,
          height: height || '100%',
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {initError && (
          <FatalError
            errorTitle="Something went wrong"
            errorMessage={initError}
            clearError={() => window.location.reload()}
            buttonText="Try again"
          />
        )}
        {step === 'digitalHuman' ? (
          <DigitalHuman
            speak={speak}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => {}}
            embeddedMode={embeddedApp}
            personaId={personaId}
            testTimeoutTime={timeoutTestTime}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home
            embeddedMode={embeddedApp}
            startSession={speak => {
              setSpeak(speak)
              goTo('digitalHuman')
            }}
            restart={() => goTo('restart')}
            headingMessage={headingMessage}
            disableDigitalHuman={disableDH}
            logo={logo}
            languageSelected={currentLanguage}
            supportedLanguages={supportedLanguages}
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
