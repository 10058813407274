import React, {
  createElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { Button, Flex, Heading, Image, Text, Link, Box } from 'rebass'
import { useTranslation } from 'react-i18next'
import unescape from 'lodash.unescape'
import InfoIcon from './informationIcon.png'
// @ts-ignore
import marksy from 'marksy'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import MayaBackButton from '../MayaBackButton/MayaBackButton'
import RestartSession from '../RestartSession/RestartSession'
import MayaHomeButton from '../MayaHomeButton/MayaHomeButton'
import { ReactComponent as ChevronDown } from './down-chevron.svg'
import { Label } from '@rebass/forms'

const compile = (sendMessage: (request: any) => void, dispatch: any) =>
  marksy({
    elements: {
      a: ({ href, children, ...otherProps }: any) => {
        if (href.includes('maya-page://')) {
          const prefixRemoved = href.split('maya-page://')[1]
          const [pageId, params] = prefixRemoved.split('/?')

          const formattedParams = params
            ? params
                .replace(/&amp;/g, '&')
                .split('&')
                .map((keyval: string) => {
                  const [key, value] = keyval.split('=')
                  return { [key]: decodeURIComponent(value) }
                })
                .reduce(
                  (
                    obj: Record<string, string>,
                    curr: Record<string, string>
                  ) => ({ ...obj, ...curr }),
                  {}
                )
            : {}
          return (
            <Text
              as="a"
              sx={
                children.some((el: any) => el?.props?.src === '/info.png')
                  ? {}
                  : styles.mayaPageLink
              }
              onClick={() => {
                dispatch({
                  type: 'mayaMessage',
                  payload: { type: 'storeInfoPage' }
                })
                sendMessage({
                  type: 'infoPage',
                  pageId: pageId,
                  params: formattedParams
                })
              }}
            >
              {children}
            </Text>
          )
        } else {
          return (
            <Link
              href={unescape(href)}
              {...otherProps}
              target="_blank"
              rel="noopener noreferrer"
              sx={styles.anchor}
            >
              {children}
            </Link>
          )
        }
      },
      h1: ({ children }: any) => (
        <Heading as="h1" sx={styles.heading}>
          {children}
        </Heading>
      ),
      h2: ({ children }: any) => (
        <Heading as="h2" sx={styles.headingTwo}>
          {children}
        </Heading>
      ),
      h3: ({ children }: any) => (
        <Heading as="h3" sx={styles.headingThree}>
          {children}
        </Heading>
      ),
      h4: ({ children }: any) => (
        <Heading as="h4" sx={styles.headingFour}>
          {children}
        </Heading>
      ),
      h5: ({ children }: any) => (
        <Heading as="h5" sx={styles.headingFive}>
          {children}
        </Heading>
      ),
      h6: ({ children }: any) => (
        <Heading as="h6" sx={styles.headingSix}>
          {children}
        </Heading>
      ),
      hr: ({ children }: any) => <hr style={styles.hr}>{children}</hr>,
      p: ({ children }: any) => <Text sx={styles.paragraph}>{children}</Text>,
      img({ src, alt, title }: any) {
        if (alt.includes('progressbar')) {
          const color = alt.split('-')[1]
          const percentage = src.replace(/\D+/g, '')
          return (
            <Flex sx={styles.loading.barContainer}>
              <Flex sx={styles.loading.barInnerContainer}>
                <Flex
                  sx={{
                    ...styles.loading.bar,
                    backgroundColor: color ? color : 'primary',
                    width: `${percentage}%`
                  }}
                />
              </Flex>
            </Flex>
          )
        } else if (src === '/info.png') {
          return (
            <Image
              sx={{
                cursor: 'pointer',
                width: '18px',
                height: '18px',
                verticalAlign: 'text-top'
              }}
              src={InfoIcon}
            />
          )
        } else {
          return <Image src={src} alt={alt} />
        }
      },
      del: ({ children }: any) => {
        return (
          <Box
            sx={{
              '& .collapsible:checked ~ .content': {
                display: 'block'
              },
              '& .content': {
                display: 'none'
              }
            }}
          >
            <Label>
              <input
                type="checkbox"
                className="collapsible"
                style={{ display: 'none' }}
              />
              <Box variant="whiteInverted" sx={styles.showMoreButton}>
                Show More
              </Box>
              <Box className="content" sx={{ display: 'none' }}>
                {children}
              </Box>
            </Label>
          </Box>
        )
      }
    },

    createElement
  })

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

const downloadBase64 = (b64Data: string, contentType: string) => {
  const blob = b64toBlob(b64Data, contentType)
  const blobUrl = URL.createObjectURL(blob)

  window.open(blobUrl, '_blank')
}

type DownloadPdfButton = { label?: string; pdf?: string; name?: string }
const DownloadPdfButton = ({ label, pdf, name }: DownloadPdfButton) => {
  const { t } = useTranslation()
  if (!pdf) return null

  const buttonText = label ? label : t('Question.downloadPdfButton')

  return (
    <Button
      onClick={() => downloadBase64(pdf, 'application/pdf')}
      sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
      variant="whiteInverted"
    >
      {buttonText}
    </Button>
  )
}

interface ActionButtonProps {
  text: string
  action: string
  link?: string
  keepInfoOpen: boolean
  important: boolean
}

const MayaInformation = () => {
  const { mayaInformation, hideInformation } = useUneeqState()
  const sendMessage = useSendSocketMessage()
  const { dispatch, showDH } = useContext(UneeqContext)
  const [showArrow, setShowArrow] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setShowArrow(false)
    if (scrollContainerRef.current) {
      const containerRef = scrollContainerRef.current
      if (containerRef?.scrollHeight > containerRef?.offsetHeight) {
        setShowArrow(true)
      } else {
        setShowArrow(false)
      }
      const hideArrowFn = () => {
        setShowArrow(false)
      }
      containerRef.addEventListener('scroll', hideArrowFn)

      return () => containerRef.removeEventListener('scroll', hideArrowFn)
    }
  }, [setShowArrow, mayaInformation])

  if (!mayaInformation?.markdown || hideInformation) return null

  const clearInformation = () => {
    clearInfoPage()
    sendMessage({ type: 'infoClosed', pageId: mayaInformation.pageId })
  }

  const sendAction = (action: string, keepInfoOpen: boolean) => {
    if (!keepInfoOpen) {
      clearInfoPage()
    }
    sendMessage({ type: 'button', action })
  }

  const clearInfoPage = () =>
    dispatch({ type: 'mayaMessage', payload: { type: 'clearInfoPage' } })

  const buttons = () => {
    if (mayaInformation.buttons) {
      const importantButtons = mayaInformation.buttons.filter(
        (btn: ActionButtonProps) => btn.important
      )
      const restOfButtons = mayaInformation.buttons.filter(
        (btn: ActionButtonProps) => !btn.important
      )

      return importantButtons
        .concat(restOfButtons)
        .map(
          ({
            text,
            action,
            keepInfoOpen,
            link,
            important
          }: ActionButtonProps) => {
            const handleClick = () => {
              if (link) {
                window.open(link, action)
                return sendAction(action, true)
              }

              sendAction(action, keepInfoOpen)
            }

            return (
              <Button
                type="submit"
                key={action}
                onClick={handleClick}
                sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
                variant={important ? 'primary' : 'whiteInverted'}
              >
                {text}
              </Button>
            )
          }
        )
    }

    return null
  }

  const compiled = compile(sendMessage, dispatch)(mayaInformation.markdown)

  return (
    <Flex
      id="information-container"
      sx={{
        ...styles.informationContainer,
        maxHeight: showDH
          ? [
              '50vh',
              '50vh',
              '50vh',
              '50vh',
              'calc(100% - 80px)',
              'calc(100% - 80px)'
            ]
          : [
              '100vh',
              '100vh',
              '100vh',
              '100vh',
              'calc(100% - 80px)',
              'calc(100% - 80px)'
            ]
      }}
    >
      <Flex ref={scrollContainerRef} sx={styles.content}>
        {compiled.tree}
      </Flex>
      <Flex sx={styles.column}>
        {showArrow && (
          // eslint-disable-next-line react/jsx-no-undef
          <Box sx={styles.arrowDownContainer}>
            <Text variant="unstyled" sx={styles.arrowDown}>
              <ChevronDown />
            </Text>
          </Box>
        )}
        <Flex sx={styles.divider} />
        <Flex id="info-actions" sx={styles.buttonsContainer}>
          {buttons()}
          <DownloadPdfButton
            pdf={mayaInformation.pdf?.base64}
            name={mayaInformation.pdf?.name}
            label={mayaInformation.pdf?.label}
          />
          {mayaInformation.buttonText ? (
            <Button
              type="submit"
              variant="whiteInverted"
              onClick={clearInformation}
              sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
            >
              {mayaInformation.buttonText ? mayaInformation.buttonText : 'Okay'}
            </Button>
          ) : null}
        </Flex>
        <Flex sx={styles.actions}>
          {!mayaInformation.hideBack && (
            <MayaBackButton
              onClick={clearInfoPage}
              sx={{ display: 'flex', alignSelf: 'center', mr: 2 }}
            />
          )}
          <Flex sx={styles.sessionActions}>
            <RestartSession
              onClick={() => dispatch({ type: 'mayaOpenConfirmRestart' })}
            />
            <MayaHomeButton
              onClick={() => dispatch({ type: 'mayaOpenConfirmLeave' })}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MayaInformation
