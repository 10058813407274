import React, { useContext, useEffect, useState } from 'react'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { Box, Text, Flex } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Hospital } from './types/Hospital'
import RadioForm from './blocks/RadioForm'
import MayaCloseButton from '../MayaCloseButton/MayaCloseButton'

import styles from './styles'
import hospitalMarker from './icons/marker.svg'
import hospitalSelectedMarker from './icons/marker-selected.svg'
import locationMarker from './icons/location.svg'
import HospitalCarousel from './blocks/Carousel'
import { Position } from './types/Position'
import { UserLocation } from './types/UserPosition'
import { getPosition } from './utils/getPosition'

declare const google: any

const mapOptions = {
  disableDefaultUI: true,
  styles: [
    {
      stylers: [
        {
          hue: '#ff1a00'
        },
        {
          invert_lightness: true
        },
        {
          saturation: -100
        },
        {
          lightness: 33
        },
        {
          gamma: 0.5
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#2D333C'
        }
      ]
    }
  ]
}

const showLocationsSwitch = (mapInfo: MapInfo) => {
  if (!mapInfo || !mapInfo.userLocations) {
    return false
  }

  return Object.keys(mapInfo.userLocations).length > 1
}

type MapInfo = {
  userLocations: Record<UserLocation, Position>
  hospitals: Hospital[]
  title: string
}

const MayaMap = React.memo(() => {
  const { dispatch } = useContext(UneeqContext)
  const sendMessage = useSendSocketMessage()
  const {
    showMap,
    mapInfo
  }: { showMap: boolean; mapInfo: MapInfo } = useUneeqState()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  })

  const [markerSelected, setMarkerSelected] = useState<Hospital | null>(null)
  const [userLocation, setUserLocation] = useState<UserLocation>('home')
  const [mapInited, setMapInited] = useState(false)
  const [map, setMap] = useState<any>(null)
  const [markerSize, setMarkerSize] = useState(32)
  const [locationMarkerSize, setLocationMarkerSize] = useState(8)

  useEffect(() => {
    if (mapInfo?.hospitals && !markerSelected) {
      const hospital = mapInfo?.hospitals[0]
      setMarkerSelected(hospital)
    }
  }, [mapInfo, markerSelected, map])

  useEffect(() => {
    if (mapInfo?.userLocations) {
      const firstLocation = Object.keys(mapInfo.userLocations)
      setUserLocation(firstLocation[0] as UserLocation)
    }
  }, [mapInfo])

  const updateHospitalMarkerSize = (zoom: number) => {
    const pixelSizeBase = 8
    const maxPixelSize = 250

    const relativePixelSize = Math.round(pixelSizeBase * zoom)

    setMarkerSize(Math.min(relativePixelSize, maxPixelSize))
  }

  const updateLocationMarkerSize = (zoom: number) => {
    const pixelSizeBase = 4
    const maxPixelSize = 120

    const relativePixelSize = Math.round(pixelSizeBase * zoom)

    setLocationMarkerSize(Math.min(relativePixelSize, maxPixelSize))
  }

  const onLoad = React.useCallback(map => {
    const bounds = new google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const centerMapIntoHospital = (h: Hospital) => {
    const position = getPosition(h)

    if (!position.lat || !position.lng) return

    map.panTo({ lat: position.lat - 0.007, lng: position.lng })
    map.setZoom(15)
  }

  const onUnmount = React.useCallback(map => {
    setMap(null)
  }, [])

  const handleMarkerSelection = (hospital: Hospital) => {
    setMarkerSelected(hospital)
    centerMapIntoHospital(hospital)
  }

  const onTilesLoaded = () => {
    if (map && !mapInited) {
      centerMapIntoHospital(mapInfo?.hospitals[0])
      setMapInited(true)
    }
  }

  if (!mapInfo || !mapInfo.hospitals) {
    return null
  }

  const shouldShowLocationSwitch = showLocationsSwitch(mapInfo)

  return showMap && isLoaded ? (
    <Box sx={styles.grid}>
      <Box sx={styles.header}>
        <Flex sx={styles.headerInfo}>
          <MayaCloseButton
            onClick={() => {
              dispatch({ type: 'clearMap' })
              sendMessage({ type: 'mapClosed' })
            }}
          />
          <Text>{mapInfo?.title}</Text>
        </Flex>
        {shouldShowLocationSwitch && (
          <RadioForm
            userLocation={userLocation}
            setUserLocation={setUserLocation}
          />
        )}
      </Box>
      <GoogleMap
        mapContainerStyle={styles.container}
        zoom={15}
        onLoad={onLoad}
        onTilesLoaded={onTilesLoaded}
        onUnmount={onUnmount}
        options={mapOptions}
        onDragStart={() => dispatch({ type: 'resetTimeout' })}
        onZoomChanged={() => {
          const zoom = map?.getZoom()
          updateHospitalMarkerSize(zoom)
          updateLocationMarkerSize(zoom)
        }}
      >
        <Marker
          animation="DROP"
          position={mapInfo?.userLocations?.[userLocation]}
          icon={{
            url: locationMarker,
            anchor: new google.maps.Point(0, 0),
            scaledSize: new google.maps.Size(
              locationMarkerSize,
              locationMarkerSize
            )
          }}
        />
        {mapInfo?.hospitals.map(hospital => (
          <Marker
            animation="DROP"
            position={getPosition(hospital)}
            icon={{
              url:
                markerSelected?.name === hospital.name
                  ? hospitalSelectedMarker
                  : hospitalMarker,
              anchor: new google.maps.Point(0, 0),
              scaledSize: new google.maps.Size(markerSize, markerSize)
            }}
            key={hospital.name}
            onClick={() => {
              setMarkerSelected({ ...hospital })
              centerMapIntoHospital(hospital)
            }}
          />
        ))}
      </GoogleMap>
      <Box sx={styles.hospitalsList}>
        {markerSelected ? (
          <HospitalCarousel
            selected={markerSelected}
            setSelected={handleMarkerSelection}
            hospitals={mapInfo?.hospitals}
            userLocation={mapInfo?.userLocations?.[userLocation]}
          />
        ) : null}
      </Box>
    </Box>
  ) : null
})

export default MayaMap
