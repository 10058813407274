import React, { useCallback, useEffect, useState } from 'react'
import { Box } from 'rebass'
import { useTranslation } from 'react-i18next'

import { ThemeProvider } from 'emotion-theming'
import theme, { THEMES } from '../theme'

import DigitalHuman from '../app/DigitalHuman'
import { getToken } from '../socket'
import FatalError from '../customPlugins/MayaErrors/FatalError'
import ChatButton from './ChatButton/ChatButton'

import { CacheProvider } from '@emotion/core'
import createCache from '@emotion/cache'
import { useDisableBodyOverflow } from 'uneeq-react-core'
import WidgetContext from '../app/contexts/WidgetContext'
import { isMobileOnly } from 'react-device-detect'
import Home from '../app/Home'
import { LanguageOption } from '../customPlugins/LanguageSelector/LanguageOption'
import { LanguageInformation } from '../customPlugins/LanguageSelector/LanguageInformation'
import { useChangeLanguage } from '../app/hooks/useChangeLanguage'
import { transformLanguageToISO } from '../app/App'

export const myCache = createCache({
  key: 'css',
  container: document.getElementById('mayaChatWidget')?.shadowRoot as any,
  speedy: false
})
interface WidgetProps {
  mayaApiKey: string
  mayaApiSecret: string
  mayaSid: string
}
const Widget = ({ mayaApiKey, mayaApiSecret, mayaSid }: WidgetProps) => {
  const [fetchingToken, setFetchingToken] = useState(false)
  const [initError, setInitError] = useState('')
  const [showChat, setShowChat] = useState(false)
  const [firstTime, setFirstTime] = useState(true)
  const [fullScreen, setFullscreen] = useState(false)
  const [appMode, setAppMode] = useState('')
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [disableDH, setDisableDH] = useState(true)
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [personaId, setPersonaId] = useState(
    process.env.REACT_APP_UNEEQ_PERSONA_ID || ''
  )
  const [supportedLanguages, setSupportedLanguages] = useState<
    LanguageOption[]
  >([])

  // When we force the user to see the widget on full screen mode
  const [forcedFullscreen, setForcedFullscreen] = useState(false)
  const isFullscreenMode = fullScreen || forcedFullscreen
  const { i18n } = useTranslation()
  const setNewLanguage = useCallback(
    (lang: LanguageInformation) => {
      if (!i18n.hasResourceBundle(lang.key, 'translation')) {
        i18n.addResourceBundle(lang.key, 'translation', lang.translation)
      }
      setCurrentLanguage(lang.key)
      setPersonaId(lang.personaId)
    },
    [i18n]
  )
  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    }
  }, [currentLanguage, i18n])

  useChangeLanguage(setNewLanguage)
  useDisableBodyOverflow(isFullscreenMode)

  const restart = () => {
    setFetchingToken(true)
    getToken(mayaApiKey, mayaApiSecret, mayaSid)
      .then(({ message, disableDigitalHuman, logo, i18n: i18nProps }: any) => {
        const headingMessage = message || ' '
        setFetchingToken(false)
        setHeadingMessage(headingMessage)
        setDisableDH(disableDigitalHuman)
        setLogo(logo)
        if (i18nProps) {
          setNewLanguage(i18nProps)
        }
      })
      .catch(({ message }: any) => {
        setInitError(message)
      })
  }

  const finishSession = () => {
    restart()
    setShowChat(false)
    setFullscreen(false)
    setForcedFullscreen(false)
    setAppMode('')
  }

  const openChat = () => {
    setShowChat(prev => !prev)
    setFirstTime(false)
    setForcedFullscreen(isMobileOnly)
  }

  // @ts-ignore
  var viewport = window.visualViewport
  const [height, setHeight] = useState(viewport?.height)
  useEffect(() => {
    const listener = () => {
      setHeight(viewport.height)
    }

    viewport?.addEventListener('resize', listener)

    return () => viewport?.removeEventListener('resize', listener)
  }, [fullScreen, viewport])

  useEffect(() => {
    getToken(mayaApiKey, mayaApiSecret, mayaSid)
      .then(
        ({
          message,
          disableDigitalHuman,
          logo,
          i18n: i18nProps,
          supportedLanguages
        }: any) => {
          const headingMessage = message || ' '
          setHeadingMessage(headingMessage)
          setDisableDH(disableDigitalHuman)
          setLogo(logo)

          if (i18nProps) {
            setNewLanguage(i18nProps)
          }

          setSupportedLanguages(
            supportedLanguages.map((lang: any) => ({
              ...lang,
              code: transformLanguageToISO(lang.code)
            }))
          )
        }
      )
      .catch(({ message }: any) => {
        setInitError(message)
      })
  }, [mayaApiKey, mayaApiSecret, mayaSid, setNewLanguage])

  return (
    <CacheProvider value={myCache}>
      <ThemeProvider theme={theme(THEMES.DARK, false)}>
        <WidgetContext.Provider
          value={{
            widgetMode: true,
            fullscreen: isFullscreenMode,
            isWidgetOpen: showChat
          }}
        >
          <Box>
            <ChatButton
              handleClick={openChat}
              showChat={showChat}
              fullScreen={isFullscreenMode}
              height={height}
            >
              {!Boolean(appMode) && (
                <Home
                  disableDigitalHuman={disableDH}
                  headingMessage={headingMessage}
                  logo={logo}
                  startSession={speak => setAppMode(speak ? 'dh' : 'chat')}
                  restart={finishSession}
                  widgetMode
                  embeddedMode={false}
                  languageSelected={currentLanguage}
                  supportedLanguages={supportedLanguages}
                />
              )}

              {!firstTime && !fetchingToken && Boolean(appMode) && (
                <DigitalHuman
                  speak={appMode === 'dh'}
                  onTimedOut={() => setAppMode('')}
                  restart={finishSession}
                  onSessionEnded={() => setAppMode('')}
                  widgetMode
                  fullscreen={isFullscreenMode}
                  embeddedMode={false}
                  setFullscreen={setFullscreen}
                  personaId={personaId}
                />
              )}
            </ChatButton>
            {initError && (
              <FatalError
                errorTitle="Something went wrong"
                errorMessage={initError}
                clearError={() => window.location.reload()}
                buttonText="Try again"
              />
            )}
          </Box>
        </WidgetContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default Widget
